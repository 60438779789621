import React from 'react';
import { Link } from 'react-router-dom';
// const credentials = btoa('admin:Alankar@123#');



const menuItems = [
  {
    id: 447,
    title: { rendered: "Stories", slug: "/" },
    object: "custom",
    parent: 0,

  },
  {
    id: 55,
    title: { rendered: "Ramayana", slug: "ramayana-epic" },
    object: "category",
    parent: 447,
  },
  {
    id: 54,
    title: { rendered: "Mahabharat", slug: "mahabharat-epic" },
    object: "category",
    parent: 447,
  },
  {
    id: 264,
    title: { rendered: "Short Stories", slug: "short-stories" },
    object: "category",
    parent: 447,
  },
  {
    id: 4291,
    title: { rendered: "Panchatantra", slug: "/category/panchatantra" },
    object: "category",
    parent: 0,
  },
  {
    id: 379,
    title: { rendered: "Blog", slug: "/blog" },
    object: "custom",
    parent: 0,
  },
  {
    id: 2326,
    title: { rendered: "Web Stories", slug: "/" },
    object: "custom",
    parent: 0,
  },
  {
    id: 53,
    title: { rendered: "About Us", slug: "/" },
    object: "page",
    parent: 0,
  },
  {
    id: 296,
    title: { rendered: "Contact Us", slug: "/" },
    object: "page",
    parent: 0,
  },
];
const NavBar = () => {
  // const [menuItems, setMenuItems] = useState([]);

  //   const [page,setPage] = useState(1);

  // Fetch the menu data from the WordPress REST API
  // useEffect(() => {
  //   const fetchMenu = async () => {
  //       try {
  //         const response = await fetch('http://localhost/sagas_react/wp-json/wp/v2/menu-items?menus=7', {
  //           method: 'GET',
  //           headers: {
  //             'Authorization': `Basic ${credentials}`,
  //             'Content-Type': 'application/json',
  //           },
  //         });
  //         const data = await response.json();
  //       //   setPage(1);
  //         setMenuItems(data);
  //       } catch (error) {
  //         console.error('Error fetching menu:', error);
  //       }
  //     };

  //   fetchMenu();
  // },[]);

  // Recursive function to render nested menu items (for dropdown menus)
  // const renderMenu = (items) => {
  //   return (
  //     <ul className='navbar-nav me-auto mb-2 mb-lg-0 '>
  //       {items.map((item) => (
  //         <li key={item.ID}  className="nav-item">
  //           <a href={item.url}  className="nav-link active" aria-current="page" >{item.title.rendered}</a>
  //           {item.parent && item.parent.length > 0 && renderMenu(item.parent)}
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };


  // Function to build the hierarchical structure of the menu
  const buildMenuTree = (items) => {
    let menu = {};
    items.forEach(item => {
      if (item.parent === 0) {
        menu[item.id] = { ...item, children: [] };
      } else {
        const parent = menu[item.parent] || Object.values(menu).find(el => el.id === item.parent);
        if (parent) {
          parent.children.push(item);
        }
      }
    });
    return menu;
  };


  const menuTree = buildMenuTree(menuItems);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">Website</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {Object.values(menuTree).map(parentItem => (
              <li key={parentItem.id} className="nav-item dropdown">
                {parentItem.children.length > 0 ? (
                  <>
                    <a className="nav-link dropdown-toggle" href="/" id={`navbarDropdown${parentItem.id}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {parentItem.title.rendered}
                    </a>
                    <ul className="dropdown-menu" aria-labelledby={`navbarDropdown${parentItem.id}`}>
                      {parentItem.children.map(childItem => (
                        <li key={childItem.id}>
                          <Link
                            className="dropdown-item"
                            to={childItem.object === 'category' ? `/category/${childItem.title.slug}` : `${childItem.title.slug}`}
                          >
                            {childItem.title.rendered}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (

                  <Link
                    className="nav-link"
                    to={`${parentItem.title.slug}`}
                  >
                    {parentItem.title.rendered}
                  </Link>

                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;