import React from 'react'
import ShowImage from './ShowImage';
import { Link, useNavigate } from 'react-router-dom';
// import { fetchCategoryNames } from '../categoryUtils';




// const credentials = btoa('admin:Alankar@123#');
export default function PostLists(props) {

  let { title, slug, post_id, excerpt, media, post_type,categories,featured_image_details } = props;

  // const [categoryNames,setCategoryNames] = useState([]);
  const formatExcerpt = (excerpt) => {
    return excerpt.replace(/\[&hellip;\]|\[…\]/g, '...');
  };
  const navigate = useNavigate();




  const handlePostClick = (slug) => {
    // Store post_id in local storage or session storage
    // localStorage.setItem('currentPostId', post.id);
    // setPostIDSinglePage(post_id,media)
    // Navigate to the post URL using slug without showing post_id in the URL
    if (post_type === "blogs") {
      navigate(`/blog/${slug}`);
    } else {
      navigate(`/${slug}`);
    }

  };
  return (
<div className="col-md-4" id={post_id} >
  <div className="card" style={{ minHeight:"450px"}}>
    <div style={{ height: "150px", width: "auto" }}>
      <ShowImage media_id={media} />
      {/* <img 
  src={featured_image_details ? featured_image_details.sizes.medium : '/dummyImage.png'} 
  className="card-img-top rounded" 
  alt={featured_image_details?.alt || 'Dummy Image'} 
  height="150"
/> */}

    </div>
      <div className="card-body">
        <div className="post-category">
          {categories.map((item, index) => (
            <React.Fragment key={item.slug}>
              <Link to={`/category/${item.slug}`}>{item.name}</Link>
              {index < categories.length - 1 && ', '}
            </React.Fragment>
          ))}
        </div>
        <div className="post-title"  dangerouslySetInnerHTML={{ __html: formatExcerpt(title) }}></div>
          {/* <p className="card-text">Pooja / September 20, 2024</p>

          <p className="card-text" dangerouslySetInnerHTML={{ __html: formatExcerpt(excerpt) }}></p> */}
          {/* <Link to={getPostLink(slug,post_id)} className="btn btn-primary">Show More</Link> */}
          <button className='btn btn-primary btn-sm' onClick={() => handlePostClick(slug)}>
            Show More
          </button>
      </div>
  </div>
</div>
    // </Link> 
  )
}


