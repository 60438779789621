import React, { useState, useEffect,useRef } from 'react';
import PostLists from './PostLists';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from './Spinner';
import PostLoader from './PostLoader';
// import {  } from 'react-router-dom';

const credentials = btoa('admin:Alankar@123#');
const Posts = ({ setProgressBar, post_type = 'posts', setPostIDSinglePage ,archieve_id='', archive_type='' }) => {
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [taxonomyChange, setTaxonomyChange] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0); // Total number of posts available
    const [loadedRecords, setLoadedRecords] = useState(0); // Number of posts loaded so far
    const [showPostLoader, setShowPostLoader] = useState(false); // Number of posts loaded so far
    // Track previous values of page and taxonomyChange
    const prevPage = useRef(page);
    const prevTaxonomyChange = useRef(taxonomyChange);
    
console.log('category:: '+ archive_type + archieve_id);


    // Reset posts and page when category changes

    // console.log('ONE: '+taxonomyChange);
    useEffect(() => {
        console.log("Category changed. Resetting posts and page.");
        setTaxonomyChange(archieve_id);
        // if (post_type === 'blogs') {
        //     setTaxonomyChange(999999999);
        // }
            
        
        setPosts([]);  // Clear posts
        setPage(1);    // Reset page to 1

        setTotalRecords(0);
        setLoadedRecords(0);
    }, [archieve_id]);

    console.log('TWO: '+taxonomyChange);
    let archeives ='' ;
    let exclude_categories = '&categories_exclude=435';
    // console.log(exclude_categories);
    if(archive_type === 'category'){
        archeives = archive_type ? `&categories=${archieve_id}` : '';
        exclude_categories = '';
    }
    if(archive_type === 'tag'){
        archeives = archive_type ? `&tags=${archieve_id}` : '';
        exclude_categories = '';
    }


    let fields = '_fields=id,slug,title,excerpt,featured_media,link,categories,featured_image_details';
    // let url = `http://localhost/sagas_react/wp-json/wp/v2/${post_type}?${fields}&orderby=date&order=desc&per_page=9&page=${page}${archeives}${exclude_categories}`;
    let url = `https://www.sagasofindia.in/wp-json/wp/v2/${post_type}?${fields}&orderby=date&order=desc&per_page=9&page=${page}${archeives}${exclude_categories}`;
   console.log(url);
   
    // Fetch posts whenever the page or category changes
    useEffect(() => {
        const fetchPosts = async () => {
            console.log('Fetching posts for page:', page);
            
            try {
                setShowPostLoader(true);
                setProgressBar(10);
                
                // console.log('Fetch URL:', url);

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Basic ${credentials}`,
                        'Content-Type': 'application/json',
                    },
                });

                setProgressBar(30);
                const data = await response.json();
                setProgressBar(50);

                const totalPosts = parseInt(response.headers.get('X-WP-Total'), 10); // Get total post count
                setTotalRecords(totalPosts); // Update total posts count
                setLoadedRecords((prevLoadedRecords) => prevLoadedRecords + data.length); // Track the number of loaded posts
                setProgressBar(70);

                // Append new posts to the existing ones without mutating state
                setPosts((prevPosts) => [...prevPosts, ...data]);
                setShowPostLoader(false);
                setProgressBar(100);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };


        // Only fetch if page or taxonomyChange has actually changed
        if (prevPage.current !== page || prevTaxonomyChange.current !== taxonomyChange) {
        fetchPosts();
        prevPage.current = page;
        prevTaxonomyChange.current = taxonomyChange;
        }
    }, [page,taxonomyChange]);

    // Function to load more posts when user scrolls
    const fetchMoreData = () => {
        setPage((prevPage) => prevPage + 1);  // Increment page count for infinite scroll
    };

    return (
        <>
            <InfiniteScroll
                dataLength={posts.length}
                next={fetchMoreData}
                hasMore={loadedRecords < totalRecords} // Check if there are more posts to load
                loader={<Spinner />}
            >
                <div className="row g-4" >
                    
                    {posts.map((post) => (
                     
                            <PostLists
                                title={post.title.rendered}
                                slug={post.slug}
                                post_id={post.id}
                                excerpt={post.excerpt.rendered}
                                media={post.featured_media}
                                setPostIDSinglePage={setPostIDSinglePage}
                                post_type={post_type}
                                categories={post.categories}
                                featured_image_details={post.featured_image_details}
                                key={post.id}

                            />

                    ))}

                {showPostLoader && <PostLoader loader={9} /> }
                </div>
            </InfiniteScroll>
        </>
    );
};

export default Posts;