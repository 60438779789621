import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

// URL of your WordPress site
// const WORDPRESS_URL = 'http://localhost/sagas_react/wp-json/wp/v2/tags';
const WORDPRESS_URL = 'https://www.sagasofindia.in/wp-json/wp/v2/tags';

const credentials = btoa('admin:Alankar@123#');
const TagCloud = () => {
  const [tags, setTags] = useState([]);

  // Function to fetch tags from the WordPress REST API
  const fetchTags = async () => {
    try {
      let allTags = [];
      let page = 1;
      let totalPages = 1;

      do {
        const response = await fetch(`${WORDPRESS_URL}?per_page=100&page=${page}`, {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${credentials}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        allTags = [...allTags, ...data];

        // Get total number of pages from the headers
        totalPages = parseInt(response.headers.get('X-WP-TotalPages'), 10);
        page++;
      } while (page <= totalPages);

      // Filter tags with count greater than 15
      const filteredTags = allTags.filter(tag => tag.count > 10);
      setTags(filteredTags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  // Fetch tags when the component mounts
  useEffect(() => {
    fetchTags();
  }, []);

  // Function to determine the font size based on the count of posts for a tag
  const getTagFontSize = (count) => {
    const minSize = 12; // Min font size for tags
    const maxSize = 18; // Max font size for tags
    const maxCount = Math.max(...tags.map(tag => tag.count)); // Highest count of any tag
    return `${Math.floor(minSize + (count / maxCount) * (maxSize - minSize))}px`;
  };

  return (<>
    <div className="card tag-cloud p-2" style={{ minHeight:"270px"}}> <span>
      {tags.length === 0 ? (
        <Spinner />
      ) : (
        tags.map(tag => (
          <Link
          key={tag.id}
          to={`/tag/${tag.slug}`}
          className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover m-2"
          style={{ fontSize: getTagFontSize(tag.count) }}
        >
          {tag.name} ({tag.count})
        </Link>
        ))
      )} </span>
    </div></>
  );
};

export default TagCloud;
