import React, { useState, useEffect } from 'react'
import ShowImageLarge from './ShowImageLarge';
import { useParams } from 'react-router-dom';

const credentials = btoa('admin:Alankar@123#');

export default function SinglePost({ setProgressBar, post_type = 'posts' }) {
    let { slug } = useParams();
    const [post, setPost] = useState('');


    useEffect(() => {
        const showPost = async () => {
            try {
                console.log(`http://localhost/sagas_react/wp-json/wp/v2/${post_type}/?_fields=id,title,content,featured_media,meta&slug=${slug}`);


                // const WORDPRESS_URL = 'https://www.sagasofindia.in/wp-json/wp/v2/tags';
                const response = await fetch(`https://www.sagasofindia.in/wp-json/wp/v2/${post_type}/?_fields=id,title,content,featured_media,meta&slug=${slug}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Basic ${credentials}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                if (data.length > 0) {
                    // Set document title using fetched data

                    document.title = data[0].meta.rank_math_title ? data[0].meta.rank_math_title : data[0].title.rendered;
                }
                setPost(data);
                console.log(data);

                setProgressBar(100);
            } catch (error) {
                console.error('Error fetching single post/blogs :', error);
            }
        };
        showPost();
    }, [slug, setProgressBar, post_type]);

    return (<>

        <div className="article-header">
            {post && (
                <div>
                    <div >
                        <ShowImageLarge media_id={post[0].featured_media} />
                    </div>
                    <article className='single-post-container'>
                        <div >
                            <h1 className='entry-title '>{post[0].title.rendered}</h1>
                            <div dangerouslySetInnerHTML={{ __html: post[0].content.rendered }} />
                        </div>
                    </article>
                </div>
            )}
        </div>

    </>
    )
}