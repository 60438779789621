import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const credentials = btoa('admin:Alankar@123#');
function RecentPosts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Use `_embed` to include featured media (thumbnail image) in the response
        const response = await fetch(`http://localhost/sagas_react/wp-json/wp/v2/posts?per_page=5&_embed`, {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${credentials}`,
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

 
  if (loading) {
    return <p>Loading recent posts...</p>;
  }

  return (
    <div >
      <h2>Recent Posts</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {posts.map((post) => {
          // Check if the post has a featured media and retrieve its thumbnail URL
          const thumbnailUrl = post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.thumbnail?.source_url || 'https://via.placeholder.com/150x100';

          return (
            <li key={post.id} style={{ display: 'flex', marginBottom: '1em' }}>
              {/* Thumbnail Image */}
              <img
                src={thumbnailUrl}
                alt={post.title.rendered}
                width="150"
                height="100"
                style={{ objectFit: 'cover', marginRight: '1em', borderRadius: '5px' }}
              />
              <div>
                {/* Post Title */}
                <Link to={post.slug} target="_blank" rel="noopener noreferrer">
                  <p>{post.title.rendered}</p>
                </Link>
                {/* Post Excerpt */}
                {/* <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} /> */}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default RecentPosts;