// PostLoader.js
import React from 'react';

const PostLoader = ({ loader }) => {
  const loaders = Array.from({ length: loader }, (_, index) => index + 1);

  return (
    <>
      {loaders.map((post_id) => (
        <div className="col-md-4" id={post_id} key={post_id}>
          <div className="card" style={{ minHeight:"450px"}} > {/* Set default height */}
            <div style={{ height: '150px', width: '100%', backgroundColor: '#e0e0e0' }} />
            <div className="card-body">
              <div className="post-category" style={{ backgroundColor: '#f0f0f0', height: '20px', width: '60%' }} />
              <div className="post-title" style={{ backgroundColor: '#f0f0f0', height: '20px', width: '80%', margin: '10px 0' }} />
              <button className="btn btn-primary btn-sm" disabled>
                Show More
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PostLoader;
