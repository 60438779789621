// import logo from './logo.svg';
// import './App.css';
import React, { useState, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import NavBar from "./Components/NavBar";
import Posts from "./Components/Posts";
import LoadingBar from "react-top-loading-bar";
import SinglePost from "./Components/SinglePost";
import TagCloud from "./Components/TagClouds";
import RecentPosts from "./Components/RecentPosts";
import categoryData from "./categoryData.json";
// import CategoryChecker from "./Components/CategoryChecker";



function App() {

  const [progress, setProgress] = useState(0);
//  const [categoryData, setCategoryData] = useState([]);

//   useEffect(() => {
//     const fetchData1 = async () => {
//       try {
//         const response = await fetch(`${window.location.origin}/categoryData.json`);
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const data = await response.json();
//         setCategoryData(data);
//       } catch (error) {
//         console.error("Error fetching JSON:", error);
//       } finally {
//        // Set loading to false after data is fetched
//       }
//     };

//     fetchData1();
//   }, []);




  const setProgressBar = useCallback((progress) => {
    setProgress(progress);
  }, []);


  return (
    <>
      <LoadingBar
        color="red"
        progress={progress} />
      <Router >
        <NavBar />
        {/* <CategoryChecker /> */}
        <div className="mt-2"> &nbsp;</div>
        <div className="container mt-5">
        <div className="row">

            <div className="col-lg-9">
              {/* <h2>Main Content Area</h2> */}
             
              <Routes>

                <Route exact path="/" element={<Posts setProgressBar={setProgressBar} />} />
                <Route exact path="/:slug" element={<SinglePost setProgressBar={setProgressBar} />} />
                <Route exact path="/blog/:slug" element={<SinglePost setProgressBar={setProgressBar} post_type='blogs' />} />
                <Route exact path="/blog" element={<Posts setProgressBar={setProgressBar} post_type='blogs' archieve_id='999999999'  />} />
                
                
                
                
                
                {categoryData.map((data, index) => {
                  return (
                    <Route
                      exact
                      path={data.path}
                      key={data.id}
                      element={
                        <Posts
                          setProgressBar={setProgressBar}
                          post_type="posts"
                          category={data.slug}
                          archieve_id={data.id}
                          archive_type={data.archive_type}
                        />}
                    />
                  );
                })}

{/* <Route exact path="/category/uncategorized" key="1" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="uncategorized" archieve_id="1" archive_type="category" />} /> 
<Route exact path="/category/mahabharat-epic" key="2" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mahabharat-epic" archieve_id="2" archive_type="category" />} /> 
<Route exact path="/category/ramayana-epic" key="3" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana-epic" archieve_id="3" archive_type="category" />} /> 
<Route exact path="/category/short-stories" key="4" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="short-stories" archieve_id="4" archive_type="category" />} /> 
<Route exact path="/category/featured" key="212" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="featured" archieve_id="212" archive_type="category" />} /> 
<Route exact path="/category/mythology" key="348" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mythology" archieve_id="348" archive_type="category" />} /> 
<Route exact path="/category/india" key="349" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="india" archieve_id="349" archive_type="category" />} /> 
<Route exact path="/category/health" key="350" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="health" archieve_id="350" archive_type="category" />} /> 
<Route exact path="/category/spiritual" key="351" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="spiritual" archieve_id="351" archive_type="category" />} /> 
<Route exact path="/category/festival" key="352" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="festival" archieve_id="352" archive_type="category" />} /> 
<Route exact path="/category/panchatantra" key="435" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="panchatantra" archieve_id="435" archive_type="category" />} /> 
<Route exact path="/category/mitrabheda" key="438" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mitrabheda" archieve_id="438" archive_type="category" />} /> 
<Route exact path="/category/mitra-sampraptah" key="439" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mitra-sampraptah" archieve_id="439" archive_type="category" />} /> 
<Route exact path="/category/kakolukiyam" key="440" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="kakolukiyam" archieve_id="440" archive_type="category" />} /> 
<Route exact path="/category/labdhapranasam" key="441" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="labdhapranasam" archieve_id="441" archive_type="category" />} /> 
<Route exact path="/category/apastamba" key="442" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="apastamba" archieve_id="442" archive_type="category" />} /> 
<Route exact path="/tag/mahabharat" key="5" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mahabharat" archieve_id="5" archive_type="tag" />} /> 
<Route exact path="/tag/ramayan" key="6" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayan" archieve_id="6" archive_type="tag" />} /> 
<Route exact path="/tag/al-nassr-vs-inter-miami" key="19" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="al-nassr-vs-inter-miami" archieve_id="19" archive_type="tag" />} /> 
<Route exact path="/tag/lionel-messi" key="20" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="lionel-messi" archieve_id="20" archive_type="tag" />} /> 
<Route exact path="/tag/cristiano-ronaldo" key="21" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="cristiano-ronaldo" archieve_id="21" archive_type="tag" />} /> 
<Route exact path="/tag/apple-tv" key="22" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="apple-tv" archieve_id="22" archive_type="tag" />} /> 
<Route exact path="/tag/india" key="23" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="india" archieve_id="23" archive_type="tag" />} /> 
<Route exact path="/tag/draupadi" key="32" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="draupadi" archieve_id="32" archive_type="tag" />} /> 
<Route exact path="/tag/swayamvar" key="33" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="swayamvar" archieve_id="33" archive_type="tag" />} /> 
<Route exact path="/tag/arjun" key="34" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="arjun" archieve_id="34" archive_type="tag" />} /> 
<Route exact path="/tag/krishna" key="35" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="krishna" archieve_id="35" archive_type="tag" />} /> 
<Route exact path="/tag/karn" key="36" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="karn" archieve_id="36" archive_type="tag" />} /> 
<Route exact path="/tag/england" key="43" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="england" archieve_id="43" archive_type="tag" />} /> 
<Route exact path="/tag/cricket" key="44" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="cricket" archieve_id="44" archive_type="tag" />} /> 
<Route exact path="/tag/2024" key="45" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="2024" archieve_id="45" archive_type="tag" />} /> 
<Route exact path="/tag/test-series" key="46" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="test-series" archieve_id="46" archive_type="tag" />} /> 
<Route exact path="/tag/ind-vs-eng" key="47" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ind-vs-eng" archieve_id="47" archive_type="tag" />} /> 
<Route exact path="/tag/ayodhya" key="48" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ayodhya" archieve_id="48" archive_type="tag" />} /> 
<Route exact path="/tag/srilanka" key="49" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="srilanka" archieve_id="49" archive_type="tag" />} /> 
<Route exact path="/tag/ayodhaya" key="50" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ayodhaya" archieve_id="50" archive_type="tag" />} /> 
<Route exact path="/tag/bharat" key="51" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="bharat" archieve_id="51" archive_type="tag" />} /> 
<Route exact path="/tag/ram" key="52" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ram" archieve_id="52" archive_type="tag" />} /> 
<Route exact path="/tag/sita" key="53" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sita" archieve_id="53" archive_type="tag" />} /> 
<Route exact path="/tag/hanuman" key="54" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="hanuman" archieve_id="54" archive_type="tag" />} /> 
<Route exact path="/tag/ram-mandir" key="55" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ram-mandir" archieve_id="55" archive_type="tag" />} /> 
<Route exact path="/tag/delhi" key="56" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="delhi" archieve_id="56" archive_type="tag" />} /> 
<Route exact path="/tag/bangalore" key="57" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="bangalore" archieve_id="57" archive_type="tag" />} /> 
<Route exact path="/tag/mumbai" key="58" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mumbai" archieve_id="58" archive_type="tag" />} /> 
<Route exact path="/tag/valentine-day" key="59" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="valentine-day" archieve_id="59" archive_type="tag" />} /> 
<Route exact path="/tag/14-february" key="60" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="14-february" archieve_id="60" archive_type="tag" />} /> 
<Route exact path="/tag/gift" key="61" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="gift" archieve_id="61" archive_type="tag" />} /> 
<Route exact path="/tag/idea" key="62" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="idea" archieve_id="62" archive_type="tag" />} /> 
<Route exact path="/tag/hindu" key="63" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="hindu" archieve_id="63" archive_type="tag" />} /> 
<Route exact path="/tag/god" key="64" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="god" archieve_id="64" archive_type="tag" />} /> 
<Route exact path="/tag/hindu-god" key="65" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="hindu-god" archieve_id="65" archive_type="tag" />} /> 
<Route exact path="/tag/laxman" key="66" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="laxman" archieve_id="66" archive_type="tag" />} /> 
<Route exact path="/tag/sita-ji" key="67" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sita-ji" archieve_id="67" archive_type="tag" />} /> 
<Route exact path="/tag/millets" key="68" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="millets" archieve_id="68" archive_type="tag" />} /> 
<Route exact path="/tag/health-benefits" key="69" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="health-benefits" archieve_id="69" archive_type="tag" />} /> 
<Route exact path="/tag/year-of-millets" key="70" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="year-of-millets" archieve_id="70" archive_type="tag" />} /> 
<Route exact path="/tag/radiant-living" key="71" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="radiant-living" archieve_id="71" archive_type="tag" />} /> 
<Route exact path="/tag/womens-health" key="72" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="womens-health" archieve_id="72" archive_type="tag" />} /> 
<Route exact path="/tag/vitality" key="73" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="vitality" archieve_id="73" archive_type="tag" />} /> 
<Route exact path="/tag/comprehensive-guide" key="74" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="comprehensive-guide" archieve_id="74" archive_type="tag" />} /> 
<Route exact path="/tag/holistic-well-being" key="75" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="holistic-well-being" archieve_id="75" archive_type="tag" />} /> 
<Route exact path="/tag/empowerment" key="76" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="empowerment" archieve_id="76" archive_type="tag" />} /> 
<Route exact path="/tag/wellness-tips" key="77" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="wellness-tips" archieve_id="77" archive_type="tag" />} /> 
<Route exact path="/tag/self-care" key="78" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="self-care" archieve_id="78" archive_type="tag" />} /> 
<Route exact path="/tag/healthy-lifestyle" key="79" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="healthy-lifestyle" archieve_id="79" archive_type="tag" />} /> 
<Route exact path="/tag/natural-healing" key="80" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="natural-healing" archieve_id="80" archive_type="tag" />} /> 
<Route exact path="/tag/rama" key="81" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="rama" archieve_id="81" archive_type="tag" />} /> 
<Route exact path="/tag/shurpanakha" key="82" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="shurpanakha" archieve_id="82" archive_type="tag" />} /> 
<Route exact path="/tag/exile" key="83" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="exile" archieve_id="83" archive_type="tag" />} /> 
<Route exact path="/tag/encounter" key="84" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="encounter" archieve_id="84" archive_type="tag" />} /> 
<Route exact path="/tag/hindu-mythology" key="85" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="hindu-mythology" archieve_id="85" archive_type="tag" />} /> 
<Route exact path="/tag/ramayana" key="86" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana" archieve_id="86" archive_type="tag" />} /> 
<Route exact path="/tag/epic-tales" key="87" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="epic-tales" archieve_id="87" archive_type="tag" />} /> 
<Route exact path="/tag/mythological-stories" key="88" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mythological-stories" archieve_id="88" archive_type="tag" />} /> 
<Route exact path="/tag/ramas-journey" key="89" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramas-journey" archieve_id="89" archive_type="tag" />} /> 
<Route exact path="/tag/shurpanakha-meeting" key="90" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="shurpanakha-meeting" archieve_id="90" archive_type="tag" />} /> 
<Route exact path="/tag/maharishi-valmiki" key="91" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="maharishi-valmiki" archieve_id="91" archive_type="tag" />} /> 
<Route exact path="/tag/valmiki-biography" key="92" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="valmiki-biography" archieve_id="92" archive_type="tag" />} /> 
<Route exact path="/tag/author" key="93" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="author" archieve_id="93" archive_type="tag" />} /> 
<Route exact path="/tag/hindu-sage" key="94" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="hindu-sage" archieve_id="94" archive_type="tag" />} /> 
<Route exact path="/tag/ancient-indian" key="95" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ancient-indian" archieve_id="95" archive_type="tag" />} /> 
<Route exact path="/tag/epic" key="96" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="epic" archieve_id="96" archive_type="tag" />} /> 
<Route exact path="/tag/poet" key="97" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="poet" archieve_id="97" archive_type="tag" />} /> 
<Route exact path="/tag/maharishi-valmiki-life" key="98" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="maharishi-valmiki-life" archieve_id="98" archive_type="tag" />} /> 
<Route exact path="/tag/valmikis-transformation" key="99" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="valmikis-transformation" archieve_id="99" archive_type="tag" />} /> 
<Route exact path="/tag/sage-to-poet" key="100" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sage-to-poet" archieve_id="100" archive_type="tag" />} /> 
<Route exact path="/tag/ramayana-creator" key="101" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana-creator" archieve_id="101" archive_type="tag" />} /> 
<Route exact path="/tag/sustainable-living" key="102" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sustainable-living" archieve_id="102" archive_type="tag" />} /> 
<Route exact path="/tag/green-lifestyle" key="103" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="green-lifestyle" archieve_id="103" archive_type="tag" />} /> 
<Route exact path="/tag/environmental-conservation" key="104" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="environmental-conservation" archieve_id="104" archive_type="tag" />} /> 
<Route exact path="/tag/energy-efficiency" key="105" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="energy-efficiency" archieve_id="105" archive_type="tag" />} /> 
<Route exact path="/tag/recycling" key="106" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="recycling" archieve_id="106" archive_type="tag" />} /> 
<Route exact path="/tag/tree-planting" key="107" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="tree-planting" archieve_id="107" archive_type="tag" />} /> 
<Route exact path="/tag/paper-free-living" key="108" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="paper-free-living" archieve_id="108" archive_type="tag" />} /> 
<Route exact path="/tag/waste-management" key="109" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="waste-management" archieve_id="109" archive_type="tag" />} /> 
<Route exact path="/tag/sustainable-transportation" key="110" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sustainable-transportation" archieve_id="110" archive_type="tag" />} /> 
<Route exact path="/tag/renewable-energy" key="111" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="renewable-energy" archieve_id="111" archive_type="tag" />} /> 
<Route exact path="/tag/loyalty" key="112" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="loyalty" archieve_id="112" archive_type="tag" />} /> 
<Route exact path="/tag/epic-journey" key="113" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="epic-journey" archieve_id="113" archive_type="tag" />} /> 
<Route exact path="/tag/courage" key="114" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="courage" archieve_id="114" archive_type="tag" />} /> 
<Route exact path="/tag/devotion-of-rama" key="115" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="devotion-of-rama" archieve_id="115" archive_type="tag" />} /> 
<Route exact path="/tag/maa-sita" key="116" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="maa-sita" archieve_id="116" archive_type="tag" />} /> 
<Route exact path="/tag/agni-pariksha" key="117" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="agni-pariksha" archieve_id="117" archive_type="tag" />} /> 
<Route exact path="/tag/mythology" key="118" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mythology" archieve_id="118" archive_type="tag" />} /> 
<Route exact path="/tag/mythological-tales" key="119" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mythological-tales" archieve_id="119" archive_type="tag" />} /> 
<Route exact path="/tag/ancient-epics" key="120" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ancient-epics" archieve_id="120" archive_type="tag" />} /> 
<Route exact path="/tag/sitas-ordeal" key="121" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sitas-ordeal" archieve_id="121" archive_type="tag" />} /> 
<Route exact path="/tag/mythological-interpretation" key="122" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mythological-interpretation" archieve_id="122" archive_type="tag" />} /> 
<Route exact path="/tag/indian-legends" key="123" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="indian-legends" archieve_id="123" archive_type="tag" />} /> 
<Route exact path="/tag/cultural-heritage" key="124" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="cultural-heritage" archieve_id="124" archive_type="tag" />} /> 
<Route exact path="/tag/religious-beliefs" key="125" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="religious-beliefs" archieve_id="125" archive_type="tag" />} /> 
<Route exact path="/tag/epic-stories" key="126" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="epic-stories" archieve_id="126" archive_type="tag" />} /> 
<Route exact path="/tag/spiritual-journeys" key="127" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="spiritual-journeys" archieve_id="127" archive_type="tag" />} /> 
<Route exact path="/tag/divine-trials" key="128" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="divine-trials" archieve_id="128" archive_type="tag" />} /> 
<Route exact path="/tag/mythological" key="129" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mythological" archieve_id="129" archive_type="tag" />} /> 
<Route exact path="/tag/insights" key="130" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="insights" archieve_id="130" archive_type="tag" />} /> 
<Route exact path="/tag/symbolism" key="131" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="symbolism" archieve_id="131" archive_type="tag" />} /> 
<Route exact path="/tag/myth-busting" key="132" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="myth-busting" archieve_id="132" archive_type="tag" />} /> 
<Route exact path="/tag/enigmatic" key="133" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="enigmatic" archieve_id="133" archive_type="tag" />} /> 
<Route exact path="/tag/narratives" key="134" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="narratives" archieve_id="134" archive_type="tag" />} /> 
<Route exact path="/tag/legendary-tales" key="135" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="legendary-tales" archieve_id="135" archive_type="tag" />} /> 
<Route exact path="/tag/historical-wisdom" key="136" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="historical-wisdom" archieve_id="136" archive_type="tag" />} /> 
<Route exact path="/tag/indian-festivals-2024" key="137" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="indian-festivals-2024" archieve_id="137" archive_type="tag" />} /> 
<Route exact path="/tag/cultural-celebrations-2024" key="138" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="cultural-celebrations-2024" archieve_id="138" archive_type="tag" />} /> 
<Route exact path="/tag/festivals-calendar-india" key="139" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="festivals-calendar-india" archieve_id="139" archive_type="tag" />} /> 
<Route exact path="/tag/top-cultural-events-2024" key="140" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="top-cultural-events-2024" archieve_id="140" archive_type="tag" />} /> 
<Route exact path="/tag/makar-sankranti" key="141" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="makar-sankranti" archieve_id="141" archive_type="tag" />} /> 
<Route exact path="/tag/sita-kidnapping" key="142" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sita-kidnapping" archieve_id="142" archive_type="tag" />} /> 
<Route exact path="/tag/ravan-abducts-sita" key="143" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravan-abducts-sita" archieve_id="143" archive_type="tag" />} /> 
<Route exact path="/tag/ramayana-episode" key="144" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana-episode" archieve_id="144" archive_type="tag" />} /> 
<Route exact path="/tag/sita-haran-story" key="145" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sita-haran-story" archieve_id="145" archive_type="tag" />} /> 
<Route exact path="/tag/ravans-deceit" key="146" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravans-deceit" archieve_id="146" archive_type="tag" />} /> 
<Route exact path="/tag/mythological-abduction" key="147" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="mythological-abduction" archieve_id="147" archive_type="tag" />} /> 
<Route exact path="/tag/sita-abandonment" key="148" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sita-abandonment" archieve_id="148" archive_type="tag" />} /> 
<Route exact path="/tag/ravans-mischief" key="149" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravans-mischief" archieve_id="149" archive_type="tag" />} /> 
<Route exact path="/tag/kidnapping-in-ramayana" key="150" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="kidnapping-in-ramayana" archieve_id="150" archive_type="tag" />} /> 
<Route exact path="/tag/abduction-narrative" key="151" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="abduction-narrative" archieve_id="151" archive_type="tag" />} /> 
<Route exact path="/tag/sitas-disappearance" key="152" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sitas-disappearance" archieve_id="152" archive_type="tag" />} /> 
<Route exact path="/tag/ramayana-plot-twist" key="153" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana-plot-twist" archieve_id="153" archive_type="tag" />} /> 
<Route exact path="/tag/ravans-evil-act" key="154" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravans-evil-act" archieve_id="154" archive_type="tag" />} /> 
<Route exact path="/tag/legendary-abduction" key="155" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="legendary-abduction" archieve_id="155" archive_type="tag" />} /> 
<Route exact path="/tag/ravan-and-sita-saga" key="156" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravan-and-sita-saga" archieve_id="156" archive_type="tag" />} /> 
<Route exact path="/tag/ramayana-dilemma" key="157" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana-dilemma" archieve_id="157" archive_type="tag" />} /> 
<Route exact path="/tag/epic-abduction-tale" key="158" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="epic-abduction-tale" archieve_id="158" archive_type="tag" />} /> 
<Route exact path="/tag/sitas-captivity" key="159" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sitas-captivity" archieve_id="159" archive_type="tag" />} /> 
<Route exact path="/tag/ravans-villainy" key="160" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravans-villainy" archieve_id="160" archive_type="tag" />} /> 
<Route exact path="/tag/ramayana-myth" key="161" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana-myth" archieve_id="161" archive_type="tag" />} /> 
<Route exact path="/tag/sitas-tragedy" key="162" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sitas-tragedy" archieve_id="162" archive_type="tag" />} /> 
<Route exact path="/tag/ravans-sinister-plot" key="163" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravans-sinister-plot" archieve_id="163" archive_type="tag" />} /> 
<Route exact path="/tag/ramayana-turning-point" key="164" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramayana-turning-point" archieve_id="164" archive_type="tag" />} /> 
<Route exact path="/tag/lakshman" key="165" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="lakshman" archieve_id="165" archive_type="tag" />} /> 
<Route exact path="/tag/diwali" key="166" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="diwali" archieve_id="166" archive_type="tag" />} /> 
<Route exact path="/tag/deepawali" key="167" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="deepawali" archieve_id="167" archive_type="tag" />} /> 
<Route exact path="/tag/kumbhkaran" key="168" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="kumbhkaran" archieve_id="168" archive_type="tag" />} /> 
<Route exact path="/tag/dasrath" key="170" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="dasrath" archieve_id="170" archive_type="tag" />} /> 
<Route exact path="/tag/sagas" key="171" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sagas" archieve_id="171" archive_type="tag" />} /> 
<Route exact path="/tag/basant-panchami" key="172" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="basant-panchami" archieve_id="172" archive_type="tag" />} /> 
<Route exact path="/tag/saraswati-puja" key="173" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="saraswati-puja" archieve_id="173" archive_type="tag" />} /> 
<Route exact path="/tag/pink-spring" key="174" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="pink-spring" archieve_id="174" archive_type="tag" />} /> 
<Route exact path="/tag/pushparag-season-of-flowers" key="175" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="pushparag-season-of-flowers" archieve_id="175" archive_type="tag" />} /> 
<Route exact path="/tag/vasant-ritu-spring-season" key="176" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="vasant-ritu-spring-season" archieve_id="176" archive_type="tag" />} /> 
<Route exact path="/tag/saraswati-mata" key="177" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="saraswati-mata" archieve_id="177" archive_type="tag" />} /> 
<Route exact path="/tag/puja-vidhi-worship-rituals" key="178" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="puja-vidhi-worship-rituals" archieve_id="178" archive_type="tag" />} /> 
<Route exact path="/tag/basanti-spring-like" key="179" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="basanti-spring-like" archieve_id="179" archive_type="tag" />} /> 
<Route exact path="/tag/basant-utsav-spring-festival" key="180" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="basant-utsav-spring-festival" archieve_id="180" archive_type="tag" />} /> 
<Route exact path="/tag/short-stories" key="181" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="short-stories" archieve_id="181" archive_type="tag" />} /> 
<Route exact path="/tag/lord-rama" key="182" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="lord-rama" archieve_id="182" archive_type="tag" />} /> 
<Route exact path="/tag/lord-hanuman" key="183" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="lord-hanuman" archieve_id="183" archive_type="tag" />} /> 
<Route exact path="/tag/history" key="184" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="history" archieve_id="184" archive_type="tag" />} /> 
<Route exact path="/tag/epic-mythological-tale" key="185" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="epic-mythological-tale" archieve_id="185" archive_type="tag" />} /> 
<Route exact path="/tag/indian" key="186" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="indian" archieve_id="186" archive_type="tag" />} /> 
<Route exact path="/tag/baali" key="187" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="baali" archieve_id="187" archive_type="tag" />} /> 
<Route exact path="/tag/sugreev" key="188" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sugreev" archieve_id="188" archive_type="tag" />} /> 
<Route exact path="/tag/dronacharya" key="189" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="dronacharya" archieve_id="189" archive_type="tag" />} /> 
<Route exact path="/tag/kaurav" key="190" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="kaurav" archieve_id="190" archive_type="tag" />} /> 
<Route exact path="/tag/pandav" key="191" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="pandav" archieve_id="191" archive_type="tag" />} /> 
<Route exact path="/tag/hastinapur" key="192" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="hastinapur" archieve_id="192" archive_type="tag" />} /> 
<Route exact path="/tag/ganga" key="193" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ganga" archieve_id="193" archive_type="tag" />} /> 
<Route exact path="/tag/narad-muni" key="194" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="narad-muni" archieve_id="194" archive_type="tag" />} /> 
<Route exact path="/tag/hampi" key="195" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="hampi" archieve_id="195" archive_type="tag" />} /> 
<Route exact path="/tag/karnatak" key="196" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="karnatak" archieve_id="196" archive_type="tag" />} /> 
<Route exact path="/tag/ved-vyas" key="197" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ved-vyas" archieve_id="197" archive_type="tag" />} /> 
<Route exact path="/tag/bhishma" key="198" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="bhishma" archieve_id="198" archive_type="tag" />} /> 
<Route exact path="/tag/lord-shiva" key="199" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="lord-shiva" archieve_id="199" archive_type="tag" />} /> 
<Route exact path="/tag/holi" key="200" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="holi" archieve_id="200" archive_type="tag" />} /> 
<Route exact path="/tag/bhagwat-geeta" key="211" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="bhagwat-geeta" archieve_id="211" archive_type="tag" />} /> 
<Route exact path="/tag/holi-2024" key="215" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="holi-2024" archieve_id="215" archive_type="tag" />} /> 
<Route exact path="/tag/holiday-2024" key="216" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="holiday-2024" archieve_id="216" archive_type="tag" />} /> 
<Route exact path="/tag/unesco" key="222" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="unesco" archieve_id="222" archive_type="tag" />} /> 
<Route exact path="/tag/caa" key="224" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="caa" archieve_id="224" archive_type="tag" />} /> 
<Route exact path="/tag/caa-2019" key="225" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="caa-2019" archieve_id="225" archive_type="tag" />} /> 
<Route exact path="/tag/nrc" key="226" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="nrc" archieve_id="226" archive_type="tag" />} /> 
<Route exact path="/tag/sadhguru" key="237" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sadhguru" archieve_id="237" archive_type="tag" />} /> 
<Route exact path="/tag/inner-engineering" key="238" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="inner-engineering" archieve_id="238" archive_type="tag" />} /> 
<Route exact path="/tag/health" key="240" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="health" archieve_id="240" archive_type="tag" />} /> 
<Route exact path="/tag/healthy" key="241" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="healthy" archieve_id="241" archive_type="tag" />} /> 
<Route exact path="/tag/lord-krishna" key="244" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="lord-krishna" archieve_id="244" archive_type="tag" />} /> 
<Route exact path="/tag/viral" key="290" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="viral" archieve_id="290" archive_type="tag" />} /> 
<Route exact path="/tag/superfoods" key="291" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="superfoods" archieve_id="291" archive_type="tag" />} /> 
<Route exact path="/tag/ayurvedic" key="292" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ayurvedic" archieve_id="292" archive_type="tag" />} /> 
<Route exact path="/tag/herbal" key="293" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="herbal" archieve_id="293" archive_type="tag" />} /> 
<Route exact path="/tag/yoga" key="294" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="yoga" archieve_id="294" archive_type="tag" />} /> 
<Route exact path="/tag/nibandh" key="298" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="nibandh" archieve_id="298" archive_type="tag" />} /> 
<Route exact path="/tag/biograpghy" key="299" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="biograpghy" archieve_id="299" archive_type="tag" />} /> 
<Route exact path="/tag/sant-kabir-das" key="300" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="sant-kabir-das" archieve_id="300" archive_type="tag" />} /> 
<Route exact path="/tag/tulsidas" key="301" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="tulsidas" archieve_id="301" archive_type="tag" />} /> 
<Route exact path="/tag/essay" key="302" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="essay" archieve_id="302" archive_type="tag" />} /> 
<Route exact path="/tag/biography" key="303" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="biography" archieve_id="303" archive_type="tag" />} /> 
<Route exact path="/tag/rahimdas" key="304" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="rahimdas" archieve_id="304" archive_type="tag" />} /> 
<Route exact path="/tag/rahim-ke-dohe" key="305" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="rahim-ke-dohe" archieve_id="305" archive_type="tag" />} /> 
<Route exact path="/tag/kabir-ke-dohe" key="306" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="kabir-ke-dohe" archieve_id="306" archive_type="tag" />} /> 
<Route exact path="/tag/ramcharitmanas" key="307" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ramcharitmanas" archieve_id="307" archive_type="tag" />} /> 
<Route exact path="/tag/anjani-putra" key="312" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="anjani-putra" archieve_id="312" archive_type="tag" />} /> 
<Route exact path="/tag/bajrangbali" key="313" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="bajrangbali" archieve_id="313" archive_type="tag" />} /> 
<Route exact path="/tag/brahma" key="314" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="brahma" archieve_id="314" archive_type="tag" />} /> 
<Route exact path="/tag/brahma-ji" key="315" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="brahma-ji" archieve_id="315" archive_type="tag" />} /> 
<Route exact path="/tag/panchmukhi-hanuman" key="339" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="panchmukhi-hanuman" archieve_id="339" archive_type="tag" />} /> 
<Route exact path="/tag/temple" key="340" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="temple" archieve_id="340" archive_type="tag" />} /> 
<Route exact path="/tag/ravan" key="342" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="ravan" archieve_id="342" archive_type="tag" />} /> 
<Route exact path="/tag/vibhishan" key="353" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="vibhishan" archieve_id="353" archive_type="tag" />} /> 
<Route exact path="/tag/bali-aur-sugreev" key="359" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="bali-aur-sugreev" archieve_id="359" archive_type="tag" />} /> 
<Route exact path="/tag/bali" key="360" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="bali" archieve_id="360" archive_type="tag" />} /> 
<Route exact path="/tag/panchatantra" key="436" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="panchatantra" archieve_id="436" archive_type="tag" />} /> 
<Route exact path="/tag/panchatantra-ki-kahani" key="437" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="panchatantra-ki-kahani" archieve_id="437" archive_type="tag" />} /> 
<Route exact path="/tag/kahani" key="443" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="kahani" archieve_id="443" archive_type="tag" />} /> 
<Route exact path="/tag/life-lessons" key="452" element={<Posts setProgressBar={setProgressBar} post_type="posts" category="life-lessons" archieve_id="452" archive_type="tag" />} /> */}

              </Routes>

            </div>

            {/* Right Column */}
            <div className="col-md-3">
             
              <TagCloud />
              <RecentPosts />
            </div>


          </div>
        </div>




<div className="footer-bottom">
    <p>Copyright © 2024 Sagas of India | Powered by Astra WordPress Theme</p>
</div>
      </Router>
    </>
  );
}

export default App;