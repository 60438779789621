import React, {useState, useEffect} from 'react'
import Spinner from './Spinner';


const credentials = btoa('admin:Alankar@123#');
export default function ShowImageLarge(props) {
    const [imageUrl, setImageUrl] = useState('');
    const [loadingImage, setLoadingImage] = useState(false);
    let {media_id} = props ;
    



    useEffect(() => {
        const showMedia = async () => {
            try {
                setLoadingImage(true);
                const response = await fetch(`https://www.sagasofindia.in/wp-json/wp/v2/media/${media_id}?_fields=id,source_url,media_details,alt_text`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Basic ${credentials}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setImageUrl(data);
                setLoadingImage(false);
            } catch (error) {
                console.error('Error fetching large Image:', error);
            }
        };
        showMedia();
    },[media_id]);

    
    return ( <>
              {loadingImage && <Spinner />}
        {imageUrl && imageUrl.media_details && imageUrl.media_details.sizes && imageUrl.media_details.sizes.full && (
    
    <img 
        src={imageUrl.media_details.sizes.full.source_url} 
        className="img-fluid rounded mb-3" 
        alt={imageUrl.alt_text || 'Image'} 
    />
)}</>
    )
}
